<template>
  <section class="container imagesset3-block" :class="theme_color">
    <div class="content">
      <template v-for="obj in value">
        <div class="image-block">
          <div class="block-text">

            <h1 v-if="obj.title_type === 'h1'"
                :class="obj.title_class"
                ref="header"
            >
              {{ getHeader(obj) }}
            </h1>
            <h2 v-if="obj.title_type === 'h2'" :class="obj.title_class" ref="header"
            >
              {{ getHeader(obj) }}
            </h2>
            <h3 v-if="obj.title_type === 'h3'" :class="obj.title_class" ref="header">
              {{ getHeader(obj) }}
            </h3>
            <div v-if="obj.title_type === 'div'" :class="obj.title_class" ref="header">
              {{ getHeader(obj) }}
            </div>


            <img preload v-if="obj.image"
                 :src="obj.image.url | baseUrl"
                 :width="obj.image.width"
                 :height="obj.image.height"
                 :alt="alt(obj.image)"
                 loading="lazy"
            />
            <div v-html="getDescription(obj)"></div>
          </div>
          <div class="block-link">
<!--            <nuxt-link :to="localePath(obj.page)" class="link" v-if="obj.page" :aria-label="getLinkName(obj)">{{-->
<!--                getLinkName(obj)-->
<!--              }}-->
<!--            </nuxt-link>-->

<!--            <a v-else-if="obj.url" class="link" :href="obj.url" :aria-label="getLinkName(obj)">{{-->
<!--                getLinkName(obj)-->
<!--              }}</a>-->
            <div v-html="getLinkName(obj)"></div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlockImages',
  props: ['value', 'theme'],
  computed: {
    theme_color() {
      return this.theme.value.theme
    },
  },
  mounted(){
    if (this.$refs['header'] && window.innerWidth >= 760) {
      const mh = this.$refs['header'].reduce((a, el) => {
        return a > el.clientHeight ? a : el.clientHeight
      });
      this.$refs['header'].forEach(el => el.style.height = mh + 'px')
    }
  },
  methods: {
    getHeader(obj) {
      return obj.header_new[this.$i18n.locale];
    },
    getDescription(obj) {
      return obj.description[this.$i18n.locale];
    },
    getLinkName(obj) {
      if (obj.links[this.$i18n.locale]) {
        return obj.links[this.$i18n.locale];
      }
    },
    alt(image) {
      return image.image_translations?.find(x => x.language.slug === this.$i18n.locale)?.title || image.title;
    },
  },
}
</script>
